import { Avatar, Popover } from 'antd';
import ArrowDown from 'assets/Icons/ArrowDown';
import DefaultAvatar from 'assets/Icons/DefaultAvatar';
import Button from 'components/Button';
import LogoApp from 'components/LogoApp';
import { IAuthUserInfo } from 'features/auth/services/types/auth';
import mineSelectors from 'features/mine/services/selectors';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import ButtonAddBlog from 'widgets/NewReview';
import useGoNewReview from 'widgets/NewReview/useGoNewReview';
import useModalLogout from './ModalLogout';

const HeaderFullAccess = () => {
  const userInfo = mineSelectors.authInfo();
  const sm = useSMLayout();
  const [modalLogoutRef, modalLogoutContext] = useModalLogout();
  const [open, setOpen] = useState(false);
  const goNewReview = useGoNewReview();

  const handleOnLogout = () => {
    setOpen(false);
    modalLogoutRef.current?.open();
  };

  if (sm) return (
    <Styled small>
      <LogoApp />
      <div style={{ flex: 1 }} />
      <div className='user-info-actions'>
        {/* <Button ghost size='large' type='text' style={{ padding: '10px 5px', borderRadius: '100%' }}><AlertIcon /></Button> */}
        <Popover
          placement='bottomRight'
          arrow={false}
          overlayInnerStyle={{ padding: 0 }}
          open={open}
          onOpenChange={setOpen}
          content={<PopoverContent onNewReviewSM={goNewReview} onLogout={handleOnLogout} userInfo={userInfo} />} trigger={['click']}>
          <Button ghost size='large' type='text' style={{ padding: '10px 8px', borderRadius: '8px' }}>
            <div className="avatar-user-group">
              <Avatar size={30} src={userInfo?.avatar || 'fail'}>
                <DefaultAvatar />
              </Avatar>
              <span>{userInfo?.name || 'Unknown'}</span>
            </div>
            <ArrowDown />
          </Button>
        </Popover>
      </div>
      {modalLogoutContext}
    </Styled>
  );

  return (
    <Styled>
      <div className='ContainerWeb large'>
        <LogoApp />
        <div style={{ flex: 1 }} />
        <div className='user-info-actions'>
          {/* <Button ghost size='large' type='text' style={{ padding: '10px 5px', borderRadius: '100%' }}><AlertIcon /></Button> */}
          <Popover
            placement='bottomRight'
            arrow={false}
            overlayInnerStyle={{ padding: 0 }}
            open={open}
            onOpenChange={setOpen}
            content={<PopoverContent onLogout={handleOnLogout} userInfo={userInfo} />} trigger={['click']}>
            <Button ghost size='large' type='text' style={{ padding: '10px 8px', borderRadius: '8px' }}>
              <div className="avatar-user-group">
                <Avatar size={38} src={userInfo?.avatar || 'fail'}>
                  <DefaultAvatar />
                </Avatar>
                <span>{userInfo?.name || 'Unknown'}</span>
              </div>
              <ArrowDown />
            </Button>
          </Popover>
        </div>
      </div>
      {modalLogoutContext}
    </Styled>
  );
};

const PopoverContent = ({ userInfo, onLogout, onNewReviewSM }: { userInfo: IAuthUserInfo | null, onLogout?: () => void; onNewReviewSM?: () => void; }) => {
  const navigate = useNavigate();
  return (
    <PopoverContentStyled>
      <div className="header-card">
        <Avatar size={80} src={userInfo?.avatar}>
          <DefaultAvatar />
        </Avatar>
        <div className="name">{userInfo?.name || 'Unknown'}</div>
        <div className="email">{userInfo?.email || '--'}</div>
        <div style={{ width: '80%' }}>
          {onNewReviewSM ?
            <Button onClick={onNewReviewSM} size='large' block success>New Review</Button> :
            <ButtonAddBlog buttonRender={(onPress) => <Button onClick={onPress} size='large' block success>New Review</Button>} />
          }

        </div>
      </div>
      <div className="card-actions">
        <Button onClick={() => navigate('/account-setting')} block size='large' type='text' className='card-action-item' >Setting</Button>
        <div style={{ borderBottom: '1px solid #D6D6D6' }} />
        <Button onClick={onLogout} block size='large' type='text' className='card-action-item' >Sign Out</Button>
      </div>
    </PopoverContentStyled>
  );
};

const PopoverContentStyled = styled.div`
  display: flex;
  width: 272px;
  padding-top: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 12px 24px -8px rgba(0, 107, 255, 0.10);
  .header-card {
    border-bottom: 1px solid #D6D6D6;
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .ant-avatar {
    border-radius: 100px;
    background: #dde6f4;
    .ant-avatar-string {
      transform: scale(1.5) translateY(3px);
    }
  }
  .name {
    color: #0B3558;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .email {
    color: 476788;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .card-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    .card-action-item {
      justify-content: flex-start;
    }
  }
`;

export default HeaderFullAccess;



const Styled = styled.div<{ small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: #FFF;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.10);
  padding: 10px 16px;

  .ContainerWeb {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .logo-app {
    height: 36px;
  }
  .user-info-actions {
    display: flex;
    align-items: center;
  }
  .avatar-user-group {
    display: flex;
    align-items: center;
    gap: 8px;
    .ant-avatar {
      border-radius: 100px;
      background: #dde6f4;
    }
    span {
      font-size: 14px;
    }
  }

  ${({ small }) => small ? `
  .logo-app {
    height: 28px;
  } 

  .user-info-actions {
    gap: 8px;
    .avatar-user-group {
      gap: 4px;
      span {
        color: #0B3558;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  `: ''}
`;