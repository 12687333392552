import { Form, Input, notification } from 'antd';
import AddressPrefix from 'assets/Icons/SignUp/AddressPrefix';
import BusinessPrefix from 'assets/Icons/SignUp/BusinessPrefix';
import DotIcon from 'assets/Icons/SignUp/DotIcon';
import EmailPrefix from 'assets/Icons/SignUp/EmailPrefix';
import PasswordPrefix from 'assets/Icons/SignUp/PasswordPrefix';
import PhonePrefix from 'assets/Icons/SignUp/PhonePrefix';
import UserNamePrefix from 'assets/Icons/SignUp/UserNamePrefix';
import Button from 'components/Button';
import InputPhone from 'components/InputPhone';
import authActions from 'features/auth/services/actions';
import authApis from 'features/auth/services/apis';
import { IBodyAPISignUpData } from 'features/auth/services/types/api';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import { useSMLayout } from 'utils/withLayout';
interface IFormData extends IBodyAPISignUpData {
  confirmPassword: string;
}

const FormSignUp = () => {
  const dispatch = useAppDispatch();
  const sm = useSMLayout();
  const [form] = Form.useForm();
  const setLoading = useSetLoadingPage();
  const navigate = useNavigate();

  const handleFinish = async (values: IFormData) => {
    setLoading(true);

    const body: IBodyAPISignUpData = {
      address: values.address,
      businessName: values.businessName,
      city: values.city,
      email: values.email,
      name: values.name,
      password: values.password,
      phone: values.phone?.replace(/[^0-9]/g, ''),
      state: values.state,
      userName: values.email,
      zip: values.zip,
    };
    try {
      const res: IResponseDataBody<string> = await authApis.signUp(body);
      const userId = res.data.data;
      if (userId) {
        storage.username.set(values.email);
        dispatch(authActions.setUserSignUpInfo({
          id: userId,
          email: values.email,
          phone: values.phone,
        }));
        navigate('/verify-otp');
      }
    } catch (error: any) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'sign up fail!',
        duration: 3
      });
    }
    finally {
      setLoading(false);
    }
  };

  if (sm) return (
    <AppStyled>
      <div className="sign-up-title" style={{ marginBottom: '24px' }}>SIGN UP</div>
      <Form form={form} onFinish={handleFinish} className='form-data'>
        <FormInfoData />
      </Form>
    </AppStyled>
  );
  return (
    <WebStyled>
      <div className="sign-up-title" style={{ marginBottom: '24px' }}>SIGN UP</div>
      <Form form={form} onFinish={handleFinish} className='form-data'>
        <FormInfoData />
      </Form>
    </WebStyled>
  );
};

export default FormSignUp;

const FormInfoData = () => {
  const form = Form.useFormInstance();
  const password: string = Form.useWatch('password', form);
  const sm = useSMLayout();
  const rules = useMemo(() => {
    return [
      { label: '8 characters minimum', active: password?.length >= 8 },
      { label: '1 number', active: /(?=.*\d)/.test(password) },
      { label: '1 lowercase letter', active: password ? /(?=.*[a-z])/.test(password) : false },
      { label: '1 special character', active: /[-+_!@#$%^&*.,?]/.test(password) },
      { label: '1 uppercase letter', active: /(?=.*[A-Z])/.test(password) },
    ];
  }, [password]);
  return (
    <>
      <div className="form-container">
        <div className='form-container-item'>
          <Form.Item name={'name'} rules={[{ required: true, message: 'Please enter name' }]}>
            <Input prefix={<UserNamePrefix />} placeholder={'Name (****)'} />
          </Form.Item>
          <Form.Item name={'businessName'} >
            <Input prefix={<BusinessPrefix />} placeholder={'Business Name'} />
          </Form.Item>
          <Form.Item name={'phone'} rules={[{ required: true, message: 'Please enter phone number' }]} >
            <InputPhone prefix={<PhonePrefix />} placeholder={'Phone Number  (***)'} />
          </Form.Item>
          <Form.Item name={'email'} rules={[{ required: true, message: 'Please enter email' }]}>
            <Input prefix={<EmailPrefix />} placeholder={'Email (****)'} />
          </Form.Item>
          {!sm ? <>
            <Form.Item name={'password'} rules={[{ required: true, message: 'Please enter password' }]}>
              <Input.Password prefix={<PasswordPrefix />} placeholder="Password (***)" />
            </Form.Item>
            <div className="confirm-pw-block">
              <Form.Item name={'confirmPassword'}
                rules={[
                  {
                    validator(rule, value) {
                      if (!value) {
                        return Promise.reject('Please enter confirm password!');
                      }
                      if (value !== password)
                        return Promise.reject('Confirm password not match!');

                      return Promise.resolve();
                    },
                  }
                ]}

              >
                <Input.Password prefix={<PasswordPrefix />} placeholder="Confirm password (***)" />
              </Form.Item>
            </div>
          </> : null}
        </div>
        <div className='form-container-item'>

          <Form.Item name={'address'} >
            <Input prefix={<AddressPrefix />} placeholder={'Address'} />
          </Form.Item>
          <Form.Item name={'city'} >
            <Input prefix={<AddressPrefix />} placeholder={'City'} />
          </Form.Item>
          <Form.Item name={'state'} >
            <Input prefix={<AddressPrefix />} placeholder={'State'} />
          </Form.Item>
          <Form.Item name={'zip'} >
            <Input prefix={<AddressPrefix />} placeholder={'Zip Code'} />
          </Form.Item>
          {sm ?
            <>
              <Form.Item name={'password'} rules={[{ required: true, message: 'Please enter password' }]}>
                <Input.Password prefix={<PasswordPrefix />} placeholder="Password (***)" />
              </Form.Item>
              <div className="confirm-pw-block">
                <Form.Item name={'confirmPassword'}
                  rules={[
                    {
                      validator(rule, value) {
                        if (!value) {
                          return Promise.reject('Please enter confirm password!');
                        }
                        if (value !== password)
                          return Promise.reject('Confirm password not match!');

                        return Promise.resolve();
                      },
                    }
                  ]}

                >
                  <Input.Password prefix={<PasswordPrefix />} placeholder="Confirm password (***)" />
                </Form.Item>
                <PasswordRuleForm rules={rules} />
              </div>
            </>
            : <div className="confirm-pw-block"><PasswordRuleForm rules={rules} /></div>}
        </div>
      </div>
      <Button size='large' onClick={() => form.submit()} block type='primary' style={{ height: '46px', marginTop: '24px' }}>Create Account</Button>
    </>
  );
};

const PasswordRuleForm = ({ rules }: {
  rules: {
    label: string;
    active: boolean;
  }[]

}) => {
  return (
    <>
      <p className='warning-text'>Please enter a password that meets all of the requirements below.</p>
      <div className='password-rules'>
        {rules.map((o, i) => (
          <div key={i} className="rule-item">
            {!o.active ? <DotIcon /> : <ValidRuleIcon />}
            <span style={{ color: !o.active ? '#808080' : '#00B466' }}>{o.label}</span>
          </div>
        ))}
      </div>
    </>
  );
};

const Styled = styled.div`
  .sign-up-title {
    color: #0B3558;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */
  }
  .confirm-pw-block {
    .warning-text {
      color: #476788;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
    }
    .password-rules {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .rule-item {
        display: flex;
        align-items: center;
        gap: 4px;
        span {
          color: #808080;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 200% */
        }
      }
    }
  }
  .ant-input-affix-wrapper {
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E7EDF6;
    background: #FFF;
    .ant-input {
      color: #0B3558;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #808080;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 1; /* Firefox */
      }
      
      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: #808080;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;
const WebStyled = styled(Styled)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .form-data {
    width: 100%;
  }
  .form-container {
    display: flex;
    gap: 16px;
    width: 100%;
    .form-container-item {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;
const AppStyled = styled(Styled)`
  .ant-form-item {
    margin-bottom: 20px;
  }
  .form-data {
    width: 100%;
  }
  .confirm-pw-block {
    .password-rules {
      .rule-item {
        span {
          font-size: 12px;
        }
      }
    }
  }
`;

const ValidRuleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.72692 18C9.40676 18 9.10261 17.8661 8.8785 17.6317L4.34817 12.8936C3.88394 12.4081 3.88394 11.6045 4.34817 11.1189C4.8124 10.6334 5.58078 10.6334 6.04501 11.1189L9.72692 14.9697L17.955 6.36414C18.4192 5.87862 19.1876 5.87862 19.6518 6.36414C20.1161 6.84967 20.1161 7.6533 19.6518 8.13882L10.5753 17.6317C10.3512 17.8661 10.0471 18 9.72692 18Z" fill="#00B466" />
  </svg>
);
