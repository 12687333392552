import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import logoIcon from 'assets/logo.png';
import { useSMLayout } from 'utils/withLayout';
import { useNavigate } from 'react-router-dom';

const LogoApp = () => {
  const sm = useSMLayout();
  const navigate = useNavigate();
  const backToHome = () => navigate('/');
  if (sm) return (
    <AppStyled ghost type='text' onClick={backToHome} style={{ padding: '5px 10px', height: 'auto', paddingBottom: '6px' }}>
      <img src={logoIcon} className='logo-app' />
    </AppStyled>
  );

  return (
    <WebStyled onClick={backToHome} ghost type='text' style={{ padding: '10px 20px', height: 'auto', paddingBottom: '12px' }}>
      <img src={logoIcon} className='logo-app' />
    </WebStyled>
  );
};

export default LogoApp;

const WebStyled = styled(Button)`
  .logo-app {
    height: 40px;
    flex-shrink: 0;
  }
`;
const AppStyled = styled(Button)`
  .logo-app {
    height: 32px;
    flex-shrink: 0;
  }
`;
