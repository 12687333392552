import LogoApp from 'components/LogoApp';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
const Header = () => {
  const sm = useSMLayout();
  if (sm) return (
    <AppStyled><LogoApp /></AppStyled>);

  return (
    <WebStyled><LogoApp /></WebStyled>
  );
};

export default Header;
const Styled = styled.div`
  background: #F2F3F8;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const WebStyled = styled(Styled)`
  display: flex;
  height: 55px;
  .logo-app {
    height: 40px;
    flex-shrink: 0;
  }
`;
const AppStyled = styled(Styled)`
  padding-top: 32px;
  .logo-app {
    height: 32px;
    flex-shrink: 0;
  }
`;
